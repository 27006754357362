/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
// --------------------common-scss-----------------------------
body{
font-family: Nunito;
background-color: #F5F7FF !important;

}
h1,
h2,
h3,
h4,
h5,h6,p{
  font-family: nunito !important;
}
.heading{
  color: #4B49AC;
    font-family: Nunito !important;
    font-size: 0.9375rem !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
}
.table-header {
  background-color: #4B49AC;
  color: #fff;
  position: sticky;
  top: 0px !important;
  z-index: 100 !important;
  th , td{
    padding: 10px 10px !important;
    text-align: left !important;
  }

}
.table-header1{
  position: sticky;
  top: 0px !important;
  background-color: #fff;
  z-index: 100;
}
.r-mt{
  margin-top: 6rem !important;
}
.comman-field{
  height: 6vh;
}
.comman-btn{
    border: none;
    padding: .5rem 1rem .5rem 1rem;
    border-radius: 10px;
}
.comman-btn1{
    border: none;
    padding: .5rem 1rem .5rem 1rem;
    border-radius: 10px;
    color: #fff;
}
.form-control{
    height: 6vh;
}

// ---------logout--modal-----------------
.modal-content {
    // margin-top: 10rem;
    // margin-left: 8rem;
    // width: 75% !important;
    padding-bottom: 1rem;
    padding-top: 1rem;
}
.form-control-color{
    height: 10px !important;
}
// -------------------------------
.description{
    overflow: hidden;
    height: 10vh;
}
.error-message{
    position: absolute;
}
// -----------------------------
.menu-item-1{
    left: -4rem !important;
    li{
        cursor: pointer;
    }
}
.menu-item-2{
    top: -.6rem !important;
    li{
        cursor: pointer;
    }
}
// --------table-responsive-content-----------
.table-responsive-content {
    overflow: none;
    -webkit-overflow-scrolling: touch;
  }
  
  @media (max-width: 575.98px) {
    .table-responsive-content {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 767.98px) {
    .table-responsive-content {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 991.98px) {
    .table-responsive-content {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 1199.98px) {
    .table-responsive-content {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

// --------common--heading-------------
.heading-section{
    i{
        cursor: pointer;
    }
    span{
        margin-left: 2rem;
    }
    h3{
        margin-top: .65rem;
    }
  }
  // -------------------------------
  @media screen and (max-width: 900px) {
    .side-section{
     width: 100% !important;
    }
   }
   @media screen and (max-width: 1000px) {
     .side-section{
      width: 100% !important;
     }
    }
// ----------search--form--common--scss-------------
.search-form{
  background-color: #fff;
  padding: .8rem 1rem .8rem 1rem;
  border-radius: 18px;
  .light{
      font-size: 1.2rem;
  }
}
// ------------audit---------------
// --------Audit---Admin--scss--start-----------------
.chip {
  display: inline-block;   
  padding: .5rem;
  font-size: 10px;  
  border-radius: 10px;
  background-color: #4B49AC;
  color: #fff;   
  margin-left: .2rem;
}

.chip:hover{
  cursor: pointer;
}

.chip-add:hover{
  cursor: pointer;
}
  
.closebtn:hover {
  cursor: pointer;
}
.partner-details:hover{
color: #4B49AC;
cursor: pointer;
}
.label-section{
cursor: pointer;
}

.table-header {
  background-color: #4B49AC;
  color: #fff;
  position: sticky;
  top: 0px !important;
  z-index: 100 !important;
  th,
  td {
    padding: 10px 10px !important;
    text-align: start !important;
  }
 
}
.table td{
  padding: 7px 7px !important;
  text-align: left !important;
}
// ---------end------------

//  width 
// ::-webkit-scrollbar {
//   width: 10px;
//   height: 7px !important;
// }

//  Track 
// ::-webkit-scrollbar-track {
//   background: #f1f1f1; 
// }
 
//  Handle 
// ::-webkit-scrollbar-thumb {
//   background: #888; 
//   border-radius: 16px;
//   z-index: 11111;
// }
// .table-responsive::-webkit-scrollbar{
//   width: 10px !important;
//   height: 7px !important;
// }
//  Handle on hover 
// ::-webkit-scrollbar-thumb:hover {
//   background: #555; 
// }
// ----------@media---------------

@media screen and (max-width: 820px) {
  .responsive-field {
    width: 95%;
  }
  .responsive-field-1{
      width: 95%;
     margin-left: 1rem;
     margin-top: .5rem;
     margin-bottom: 0 !important;
  }

  .col-form-label {
      padding-top: 0 !important; 
      padding-bottom: 0 !important; 
      margin-bottom: 0;
      font-size: inherit;
      line-height: 1;
  }
.px-3{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.Select-Duration{
  margin-top: 1rem;
}
.custom-select{
  height: 4.8vh !important;
}
.show-nav{
  width: 30% !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.hide-nav{
  width: 30% !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.login-form{
  width: 100% !important;
}
}

@media screen and (max-width: 480px) {
  .responsive-field {
    width: 95% !important;
  }
  .responsive-select{
    width: 100% !important;
    margin-left: 0 !important;
  }
  .responsive-field-1{
      width: 95% !important;
     margin-left: 1rem;
     margin-top: .8rem;
     margin-bottom: 0 !important;
  }

  .col-form-label {
      padding-top: 0 !important; 
      padding-bottom: 0 !important; 
      margin-bottom: 0;
      font-size: inherit;
      line-height: 1;
  }
.px-3{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.custom-select{
  height: 4.8vh !important;
}
.Select-Duration{
  margin-top: 1rem;
}
.show-nav{
  width: 60% !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.hide-nav{
  width: 60% !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.login-form{
  width: 100% !important;
}
.brand-logo{
  img{
    width: 25% !important;
  }
}

}

@media screen and (max-width: 480px) {
  .form-select{
      width: 100%;
      // margin-left: 0;
      height: 100%;
  }
}
// ----------table-responsive----------
.table-responsive{
  min-height: 38vh;
  max-height: 50vh;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
